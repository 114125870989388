<template>
  <div>
    <DateCompWithTime :propsData="DateTimeObj1" @complete="onChangeDateAndTime($event, bookings[0].bookingNum)" />
    <DateCompWithTime :propsData="DateTimeObj2" @complete="onChangeDateAndTime($event, bookings[1].bookingNum)" />
  </div>
</template>
<script>
import DateCompWithTime from "@/components/commonV2/ui/DateCompWithTime.vue";
export default {
  components: { DateCompWithTime },
  props: {
    bookings: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      DateTimeObj1: {
        date: this.bookings[0].targetDate,
        time: this.bookings[0].targetTime,
      },
      DateTimeObj2: {
        date: this.bookings[1].targetDate,
        time: this.bookings[1].targetTime,
      }
    }
  },
  watch: {
    bookings: {
      handler(val, oldVal) {
        this.DateTimeObj1 = {
          date: val[0].targetDate,
          time: val[0].targetTime,
        }
        this.DateTimeObj2 = {
          date: val[1].targetDate,
          time: val[1].targetTime,
        }
      },
      immediate: true, // 컴포넌트가 생성되자마자 즉시 실행
    },
  },
  methods: {
    onChangeDateAndTime(e, bookingNum) {
      console.log("", e, bookingNum);
      this.bookings[bookingNum - 1].targetDate = e.date;
      this.bookings[bookingNum - 1].targetTime = e.time;
    },
  },
}
</script>
<style lang="">

</style>