<template>
  <div>
    <v-textarea 
      counter="1000" maxLength="1000" 
      v-model="text" 
      :label="label"
      :placeholder="placeholder" 
      :rules="rules"
      @change="$emit('inputChange', text)" />
  </div>
</template>
<script>

export default {
  props: {
    propsText: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    rules: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },
  watch: {
    propsText: {
      handler(val, oldVal) {
        this.text = val;
      },
      immediate: true // 컴포넌트가 생성되자마자 즉시 실행
    }
  },
  data() {
    return {
      formHeight: 52,
      text: this.propsText
    };
  },
  methods: {
  },
};
</script>
<style lang="">
</style>