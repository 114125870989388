<template>
  <div class="birthDayBox">
    <SelectBox
      selectStyleWidth="width: 110px"
      :value="month"
      :items="itemMonth"
      @changeSelect="comboChange($event, 'month')"
    />
    <SelectBox
      selectStyleWidth="width: 70px"
      :value="day"
      :items="itemDay"
      @changeSelect="comboChange($event, 'day')"
    />
    <SelectBox
      selectStyleWidth="width: 100px"
      :value="year"
      :items="itemYear"
      @changeSelect="comboChange($event, 'year')"
    />
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    SelectBox,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      console.log("", this.value);
      // 예외처리
      if (this.value === "" || this.value === null) {
        this.year = "";
        this.month = "";
        this.day = "";
        return;
      }

      // date 분리해서 각 year, month, day에 입력
      // const [year, month, day] = this.value.split('-');
      this.year = Number(this.value.substring(0, 4));
      this.month = this.value.substring(4, 6);
      this.day = this.value.substring(6, 8);
      console.log("value : ", this.year, this.month, this.day);
    },
  },
  data() {
    return {
      year: 1970,
      month: "01",
      day: "01",
      itemYear: [],
      itemMonth: formatService.monthEnType(),
      itemDay: [],
    };
  },
  created() {
    for (var i = 1900; i <= new Date().getFullYear(); i++) {
      this.itemYear.push({ value: i, text: i });
    }
    for (var i = 1; i <= 31; i++) {
      this.itemDay.push({
        value: String(this.$helper.zero_plus(i)),
        text: this.$helper.zero_plus(i),
      });
    }
  },
  methods: {
    comboChange(e, type) {
      // console.log("", type, e);
      let fullDate = "";
      if (type === "year") this.year = e;
      if (type === "month") this.month = this.$helper.zero_plus(e);
      if (type === "day") this.day = this.$helper.zero_plus(e);
      fullDate = this.year + this.month + this.day;
      if (this.year === "" || this.month === "" || this.day === "") {
        this.formedDate = "";
      } else {
        this.formedDate = fullDate;
      }
      // console.log("this.formedDate : ", this.formedDate);
      this.$emit("updateSelect", this.formedDate);
    },
  },
};
</script>
<style lang="scss">
.birthDayBox {
  display: flex;
  width: 380px;
}
</style>