<template>
  <SelectBox 
    :label="propsLabel"
    :value="select" 
    :items="items" 
    @changeSelect="updateSelect" />
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
export default {
  components: {
    SelectBox,
  },
  props: {
    propsLabel: {
      type: [String],
      default: "",
    },
    selectedValue: {
      type: [Number, String],
      default: "",
    },
    items: {
      type: [Object, Array],
    },
  },
  watch: {
    selectedValue(val) {
      this.select = this.selectedValue;
    },
  },
  data() {
    return {
      select: this.selectedValue,
    };
  },
  methods: {
    updateSelect(e) {
      this.select = e;
      this.$emit("updateSelect", e);
    },
  },
};
</script>