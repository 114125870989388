<template>
  <v-row class="d-flex" >
    <v-col cols="3">
      <v-text-field
        label="지불내용"
        placeholder=""
        v-model="item.productNameKr"
      />
    </v-col>
    <v-col cols="2">
      <v-text-field
        type="number"
        label="지불금액"
        placeholder=""
        prefix="￦"
        v-model="item.paidValue"
        @input="changeInput(item)"
      />
    </v-col>
    <v-col cols="2">
      <!-- <div class="afterLabel">추후산정</div> -->
      <v-text-field
        ref="refCharge"
        type="type"
        label="수수료"
        placeholder=""
        prefix="￦"
        v-model="item.charge"
        :disabled="chargeDisabled"
      />
    </v-col>
    <v-col cols="4">
      <RadioComps
        :prosIsRow="true"
        :items="itemChargeType"
        :prosSelected="item.chargeType"
        @changeType="
          item.chargeType = $event;
          calChargeValue(item);
          changeType($event)"
      />
      <!-- <v-btn color="primary" depressed small @click="clickDeletePay(index)">삭제</v-btn> -->
    </v-col>
    <v-col cols="1">
      <v-btn color="primary" depressed small @click="$emit('clickDeletePay', index)">삭제</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { RadioComps },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    bookingData: {
      type: [Object, Array]
    },
  },
  data() {
    return {
      chargeDisabled: true,
      itemChargeType: formatService.itemChargeType(),
    }
  },
  methods: {
    changeType(e) {
      console.log('changeType', e);
      this.chargeDisabled = true;
      // this.calChargeValue(this.item);
      if(e === '이벤트') {
        // 수수료 disable해제
        this.chargeDisabled = false;
        this.item.charge = '추후산정';
        // this.chargeDisabled = true;
        setTimeout(() => {
          this.chargeDisabled = true;
          // this.$refs.refCharge.focus();
        }, 300);
      }
    },
    changeInput(item) {
      console.log('item', item, Number(item.paidValue));
      if (Number(item.paidValue) < 0) {
        this.$helper.showTostMessage("", "지불할 금액이 -일수는 없습니다.", "error");
        return;
      }
      this.calChargeValue(item);
      this.$emit("changeInput", item)
    },
    // 수수료 계산
    calChargeValue(item) {
      if(item.chargeType === '이벤트') {
        // originCharge값
        item.charge = '추후산정'; //item.originCharge;
        this.chargeDisabled = true; // 다시잡금기
        return;  
      }
      let targetCharge = 0;
      let defaultSurgeryCharge = this.$helper.defineChargeWidthNation(this.bookingData).surgery;
      let defaultProcedureCharge = this.$helper.defineChargeWidthNation(this.bookingData).procedure;
      if (item.chargeType === '수술') {
        targetCharge = defaultSurgeryCharge / 100;
      } else if (item.chargeType === '시술') {
        targetCharge = defaultProcedureCharge / 100;
      }
      item.charge = (Number(item.paidValue) * targetCharge).toFixed(1);
      this.$forceUpdate();
      console.log('calChargeValue', item.charge);
    },
    // calPaymentValue() {
    //   this.totalPayNum = 0;
    //   this.selected.forEach(element => {
    //     this.totalPayNum = this.totalPayNum + Number(element.paidValue);
    //   });
    // },
  },
}
</script>
<style lang="scss">
.afterLabel {
  background: white;
  position: relative;
  top: 35px;
  left: 20px;
  z-index: 2;
}
</style>