<template>
  <div>
    <v-row>
      <v-col>
        <TextForm 
          label="현재복용약" 
          placeholder="현재 복용중인 약정보를 입력해주세요"
          :propsText="patientOptionInfo.medication"
          @inputChange="patientOptionInfo.medication = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextForm 
          label="알러지정보" 
          placeholder="알러지 정보를 입력해주세요"
          :propsText="patientOptionInfo.allergy"
          @inputChange="patientOptionInfo.allergy = $event" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextForm from "@/components/commonV2/ui/TextForm.vue";
const rulesService = require("@/utils/rules.js");
export default {
  components: { TextForm },
  props: {
    patientOptionInfo: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    }
  },
}
</script>
<style lang="">

</style>