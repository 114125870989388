<template>
  <div class="subMain">
    <div class="headTitle">{{ $t("patient.emergencyContactTitle") }}</div>
    <v-card-text>
      <v-row>
        <v-col>
          <SelectBox
            selectStyleWidth="width: 100%"
            :label="$t('patient.detail.emergencyContact.relationship')"
            ref="refRelationship"
            :value="data.emergencyContactRelation"
            :items="emergencyContactRelationList"
            :required="true"
            @updateSelect="emergencyContactRelationListHandler"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.emergencyContact.contactName')"
            :placeholder="$t('patient.detail.emergencyContact.contactName')"
            ref="refName"
            v-model="data.emergencyContactName"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.emergencyContact.contactPhone')"
            :placeholder="$t('patient.detail.emergencyContact.contactPhone')"
            ref="refPhone"
            v-model="data.emergencyContactPhone"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBox,
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      emergencyContactRelationList:
        formatService.emergencyContactRelationList(),
    };
  },
  methods: {
    emergencyContactRelationListHandler(e) {
      this.data.emergencyContactRelation = e;
    },
  },
};
</script>
<style lang="scss">
.headTitle {
  padding-bottom: 10px;
}
</style>