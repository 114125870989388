<template>
  <v-card-text>
    <v-form id="patientAddForm" ref="form">
      <v-row>
        <v-col>
          <!-- <RadioComps
            :items="patientTypeItems"
            :prosSelected="patientTypeValue"
            @changeType="patientTypeChangeType"
          /> -->
          <!-- <CheckComps
            :isHorizontal="true"
            :items="serviceTypeItems"
            :value="serviceTypeValue"
            @changeType="changeServiceType"
          /> -->
        </v-col>
      </v-row>
      <div class="hGap" />
      <v-row justify="start" align="start">
        <v-col cols="12">
          <Profile ref="refProfile" :data="patientData" />

          <EmergencyContact ref="refEmergencyContact" :data="patientData" />

          <!-- <PatientDescription :data="patientData" /> -->
        </v-col>

        <!-- <v-col cols="5"> -->
        <!-- <v-row justify="start" align="start"> -->
        <!-- <PatientSurgeryPlan ref="refPatientSurgeryPlan" :data="patientData" /> -->

        <!-- <div class="hGap" /> -->

        <!-- <EmergencyContact ref="refEmergencyContact" :data="patientData" /> -->

        <!-- <div class="hGap" /> -->

        <!-- <PatientPurchaseInfo ref="refPatientPurchaseInfo" :data="patientData" /> -->
        <!-- </v-row>
        </v-col> -->
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import Profile from "./patient/PatientProfileV1.vue";
import Contact from "./patient/PatientContact.vue";
import PatientSurgeryPlan from "./patient/PatientSurgeryPlan.vue";
import PatientPurchaseInfo from "./patient/PatientPurchaseInfo.vue";
import EmergencyContact from "./patient/PatientEmergencyContact.vue";
import Prescription from "./patient/PatientPrescription.vue";
// import PatientDescription from "./patient/PatientDescription.vue";
// import ACPConsent from '@/components/user/patientActionCarePlan/comp/ACPConsent.vue';

const formatService = require("@/utils/format.js");

export default {
  components: {
    RadioComps,
    CheckComps,
    Profile,
    PatientSurgeryPlan,
    PatientPurchaseInfo,
    Contact,
    EmergencyContact,
    Prescription,
    // PatientDescription,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  watch: {
    patientData(val) {
      if (val === null) return;
      this.serviceTypeValue =
        val.serviceType === ""
          ? []
          : this.$helper.changeStringToArr(val.serviceType);
    },
  },
  data() {
    return {
      patientTypeValue: "consent",
      patientTypeItems: formatService.patientTypeItems(),
      serviceTypeValue:
        this.patientData.serviceType === ""
          ? []
          : this.$helper.changeStringToArr(this.patientData.serviceType),
      serviceTypeItems: formatService.serviceTypeItems(),
    };
  },
  methods: {
    changeServiceType(e) {
      // console.log("", e);
      this.patientData.serviceType = e.toString();
    },
    patientTypeChangeType(e) {
      this.patientData.patientType = e;
    },
    // changeServiceType(e) {
    //   console.log('changeServiceType', e);

    //   // CCM,RPM형태 -> 2개이고... -> 앞이 RPM이면 -> CCM,RPM으로 변경 처리(검색을 위해서)
    //   if (e.length === 2) {
    //     if (e[0] === 'RPM') {
    //       e[0] = 'CCM';
    //       e[1] = 'RPM';
    //     }
    //   }
    //   this.data.serviceType = e.toString();
    // },
  },
};
</script>

<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}
.hGap {
  height: 20px !important;
}
.subMain {
  width: 100%;
}
</style>