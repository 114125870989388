<template>
  <SelectBox
    ref="selectBox"
    :label="label"
    :selectStyleWidth="selectStyleWidth"
    :value="select"
    :items="list"
    :required="required"
    @changeSelect="updateSelect"
  />
</template>
<script>
import { mapActions } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";

export default {
  components: {
    SelectBox,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    selectStyleWidth: {
      type: String,
      default: "width: 250px",
    },
    userData: {
      type: [Array, Object],
    },
    hospital_id: {
      type: [Number, String],
      default: 0,
    },
    selectedValue: {
      type: [Number, String],
      default: "",
    },
    searchVal: {
      type: String,
      default: "doctor",
    },
    searchValType: {
      type: String,
      default: "Primary Physician",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      select: this.selectedValue,
      reqData: {
        endpoint: "physician",
        hospital_id: this.hospital_id,
        searchGbn: "",
        searchVal: this.searchVal,
      },
    };
  },
  watch: {
    userData(val) {
      this.getData();
    },
    hospital_id(val, oldVal) {
      this.reqData.hospital_id = val;
      this.getData();
    },
    selectedValue(val) {
      this.select = this.selectedValue;
      if (val === 0) this.select = "";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    _selectOne() {
      console.log("selectOne");
      // 의사인가 1명인 경우 처리
      if (this.searchVal === "doctor") {
        if (this.list.length > 1) {
          console.log("test", this.list.length);
          this.select = this.list[1].value;
          this.$emit("updateSelect", this.select);
        }
      }
    },
    getData() {
      this.list = [];
      const b = { value: "", text: "Select " + this.searchValType };
      this.list.push(b);
      this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        res.data.forEach((element) => {
          const d = {
            value: element.user_id,
            text: element.name + "/" + element.staffType,
          };
          this.list.push(d);
        });
        this._selectOne();
      });
    },
    updateSelect(e) {
      this.select = e;
      this.$emit("updateSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>