<template>
  <!-- <v-col> -->
  <div class="subMain">
    <div class="headTitle">{{ $t('patient.surgeryPlanTitle') }}</div>
    <div class="patient_vGap"></div>
    <div class="patientEdit_vGap" v-if="data.user_id === 0"></div>
    <v-card-text>
      <v-row>
        <v-col>
          <DatePickerWithInputText
            :label="$t('patient.detail.hopeSurgeryDay1')"
            :value="$helper.changeDateToString(data.patientAddInfo1.hopeSurgeryDay1)"
            @onChangeDate="onChangeHopeSurgeryDay1"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DatePickerWithInputText
            :label="$t('patient.detail.hopeSurgeryDay2')"
            :value="$helper.changeDateToString(data.patientAddInfo1.hopeSurgeryDay2)"
            @onChangeDate="onChangeHopeSurgeryDay2"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
  <!-- </v-col> -->
</template>
<script>
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";

const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    DatePickerWithInputText,
  },
  props: {
    data: {
      type: [Object, Array],
    },
    staffTypeList: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
    onChangeHopeSurgeryDay1(e) {
      this.data.patientAddInfo1.hopeSurgeryDay1 = e;
    },
    onChangeHopeSurgeryDay2(e) {
      this.data.patientAddInfo1.hopeSurgeryDay2 = e;
    },
  },
};
</script>
<style lang="scss">
.patient_vGap {
  height: 10px;
}
.patientEdit_vGap {
  height: 7px;
}
</style>