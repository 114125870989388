<template>
  <div class="d-flex">
    <SelectBox
      label="메신저종류"
      selectStyleWidth="width: 200px"
      :value="messengerType"
      :items="messengerItems"
      :multiple="false"
      :chips="false"
      @changeSelect="changeSelect"
    />
    <div class="messengerText">
      <v-text-field 
        label="메신져아이디" width="100%" placeholder="메신져아이디" 
        ref="refMessengerId" 
        v-model="messengerId" :rules="rules"
        @change="$emit('changeInput', $event)" />
    </div>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");

export default {
  components: { SelectBox },
  props: {
    messengerType: {
      type: String,
    },
    propsMessengerId: {
      type: String,
    },
    rules: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      messengerItems: formatService.messengerItems(),
      messengerId: this.propsMessengerId
    };
  },
  methods: {
    changeSelect(e) {
      this.$emit("changeSelect", e);
      this.$refs.refMessengerId.focus();
    },
  },
};
</script>
<style lang="scss">
.messengerText {
  padding-top: 5px;
}
</style>