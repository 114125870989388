<template>
  <v-card class="mx-auto my-1" width="100%" link @click="RowClick(avatarData)">
    <v-row class="photoBox">
      <v-col cols="1">
        <v-avatar size="50px">
          <v-icon class="iconStyle" v-if="avatarData.isPhoto === 'N'">
            mdi-account-circle
          </v-icon>
          <v-img v-else alt="Avatar" :src="avatarData.userPhotoImg" />
        </v-avatar>
      </v-col>
      <v-col>
        <v-row>
          <b>
            {{ avatarData.name }}({{ avatarData.gender }} /
            {{ avatarData.age }} / {{ avatarData.serviceType }})
          </b>
        </v-row>
        <v-row class="regTag" v-if="avatarData.registrationDate">
          Registration Date :
          {{
            $helper.changeDateToStringForDp(avatarData.registrationDate, true)
          }}
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    avatarData: {
      type: [Object, Array],
    },
  },
  methods: {
    RowClick(item) {
      this.$emit("clickRow", item);
    },
  },
};
</script>
<style lang="">
</style>