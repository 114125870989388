<template>
  <v-row>
    <v-col>
      <TextForm 
        label="Address" 
        placeholder="..를 입력해주세요"
        :propsText="bookingData.address"
        :rules="[filedRules.required]"
        @inputChange="bookingData.address = $event" />
    </v-col>
  </v-row>
</template>
<script>
import TextForm from "@/components/commonV2/ui/TextForm.vue";
const rulesService = require("@/utils/rules.js");
export default {
  components: { TextForm },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    }
  },
}
</script>
<style lang="">

</style>