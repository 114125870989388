<template>
  <v-col>
    <div class="subMain">
      <div class="headTitle vGap">{{ $t('patient.contactTitle') }}</div>
      <v-card-text>
        <v-row>
          <v-col>
            <SelectBox
              ref="refCountry"
              :value="country"
              :items="countryList"
              @changeSelect="countryListHandler"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DuplCheckType
              checkInputType="string"
              checkType="mobile"
              :checkedValue="data.checkedMobile"
              :value="data.cell"
              :prosDuplicateChecked="data.duplicateMobileChecked"
              :userData="data"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="PHONE (HOME/OFFICE)"
              ref="refPhone"
              v-model="data.phone"
              placeholder="PHONE (HOME/OFFICE)"
              :rules="[cellRules.min, cellRules.max]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DatePickerWithInputText
              label="Registration Date"
              :value="$helper.changeDateToString(data.registrationDate)"
              @onChangeDate="onChangeRegistrationDate"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <Address :data="data" />
    </div>
  </v-col>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import DuplCheckType from "@/components/commonV2/ui/DuplCheckType.vue";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import Address from "@/components/commonV2/ui/AddressListItem.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    SelectBox,
    DuplCheckType,
    Address,
    DatePickerWithInputText,
  },
  props: {
    data: {
      type: [Object, Array],
    },
    staffTypeList: {
      type: [Object, Array],
    },
  },
  watch: {
    data(val) {
      this.country = val.country;
    },
  },
  data() {
    return {
      country: this.data.country,
      countryList: formatService.countryList(),
      cellRules: rulesService.cellRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    countryListHandler(e) {
      console.log('e', e);
      
      this.country = e;
      this.data.country = e;
    },
    onChangeRegistrationDate(e) {
      this.data.registrationDate = e;
    },
  },
};
</script>
<style lang="scss">
.vGap {
  margin-bottom: 10px;
}
</style>