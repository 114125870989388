<template>
  <div>
    <v-row>
      <v-col>
        <TextForm 
          label="진단명" 
          placeholder="진단명을 입력해주세요(검사 판독지, 의사진단서, 의사소견서 있으시면 함께 첨부해주세요)"
          :propsText="patientOptionInfo.diagnosis"
          @inputChange="patientOptionInfo.diagnosis = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextForm 
          label="증상" 
          placeholder="증상를 입력해주세요"
          :propsText="patientOptionInfo.symptoms"
          @inputChange="patientOptionInfo.symptoms = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextForm 
          label="과거력" 
          placeholder=""
          :propsText="patientOptionInfo.pastHistory"
          @inputChange="patientOptionInfo.pastHistory = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextForm 
          label="가족력" 
          placeholder=""
          :propsText="patientOptionInfo.familyHistory"
          @inputChange="patientOptionInfo.familyHistory = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TextForm 
          label="진료목적" 
          placeholder=""
          :propsText="patientOptionInfo.purpose"
          @inputChange="patientOptionInfo.purpose = $event" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextForm from "@/components/commonV2/ui/TextForm.vue";
const rulesService = require("@/utils/rules.js");
export default {
  components: { TextForm },
  props: {
    patientOptionInfo: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    }
  },
}
</script>
<style lang="">

</style>