<template>
  <v-row>
    <v-col class="ma-0 pa-0">
      <v-card-text class="card-title ma-0">
        환자 정보
        <!-- 도움말 -->
        <HelpMsg :msgType="helpMsg" />
      </v-card-text>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <SearchTopUIComp
        :placeholder="$t('common.component.searchTop.searchInputWord2')"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <div class="uiSelectBox">
        <SelectBoxClinic @updateSelect="updateSelectClinic" />
        <!-- <SelectBoxForTypeList
          :items="serviceList"
          @updateSelect="updateSelectService"
        /> -->
        <!-- <SelectBoxForTypeList
          :items="diagnosisList"
          @updateSelect="updateSelectDiagnosis"
        /> -->
      </div>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <!-- :btnIsShow="reqData.hospital_id === 0 ? false : true" -->
      <TopTable
        :btnLabel="$t('common.button.new')"
        :btnIsShow="true"
        :IsSwitchShow="true"
        :xlsxBtnIsShow="false"
        :switchLabel="switchLabel"
        :data="patientData"
        @clickXlsxBtn="xlsxSave"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
        @changeSwitch="changeSwitch"
      />
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <PatientPhotoCard
        v-if="viewPhotoMode === 'Y'"
        :data="patientData"
        @clickRow="nameClick"
        @nameClick="clickRow"
        @memoClick="memoClick"
        @bookingClick="bookingClick"
        @payClick="payClick"
      />

      <DataTable
        v-else
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :perPage="10000"
        :tableKey="'rowSeq'"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="patientInfoColumns"
        :inputData="patientData"
        @clickRow="clickRow"
        @memoClick="memoClick"
        @nameClick="nameClick"
      />
    </v-col>

    <!-- 편집창 -->
    <DialogPatientAdd
      ref="refPatientAdd"
      @complete="userEditorCompleteSaveData"
    />

    <!-- 메모창 -->
    <DialogMemoInput ref="refMemoInput" @complete="memoSaveComplete" />

    <!-- 예약정보 -->
    <DialogPatientBookingInfo ref="refDialogPatientBookingInfo" @complete="getData"/>

    <!-- 수납처리 -->
    <DialogPatientPayment ref="refDialogPatientPayment" />

    <!-- patientDrawer -->
    <v-navigation-drawer
      v-model="patientDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      :width="patientDrawerWidth"
    >
      <patient-drawer
        ref="refPatientDrawer"
        @closeDrawer="patientDrawer = false"
        @careNoteSaveComplete="
          patientDrawer = !patientDrawer;
          getData();
        "
        @withoutSaveClose="patientDrawer = !patientDrawer"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="
              patientDrawer = !patientDrawer;
              onClose();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </patient-drawer>
    </v-navigation-drawer>

    <LoadingProgress :isLoading="isLoading" />
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LoadingProgress from "./comp/LoadingProgress.vue";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import SelectBoxForTypeList from "@/components/commonV2/ui/SelectBoxForTypeList.vue";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogPatientAdd from "@/components/commonV2/Dialog/DialogPatientAdd.vue";
import DialogMemoInput from "@/components/commonV2/Dialog/DialogMemoInput.vue";
import DialogPatientBookingInfo from "@/components/commonV2/Dialog/DialogPatientBookingInfo.vue";
import DialogPatientPayment from "@/components/commonV2/Dialog/DialogPatientPayment.vue";
import HelpMsg from "@/components/commonV2/helpMsg/HelpMsg.vue";
import PatientPhotoCard from "./PatientPhotoCard.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    PatientDrawer: () => import("./tabs/_Tabs.vue"),
    LoadingProgress,
    SearchTopUIComp,
    TopTable,
    DataTable,
    SelectBoxClinic,
    SelectBoxForTypeList,
    DialogPatientAdd,
    DialogMemoInput,
    PatientPhotoCard,
    DialogPatientBookingInfo,
    DialogPatientPayment,
    HelpMsg,
  },
  data() {
    return {
      isLoading: false,
      viewPhotoMode: "Y",
      patientDrawer: false,
      patientDrawerWidth: "90%",
      loading: false,
      loadingText: "Loading list...",
      patientInfoColumns: formatService.patientInfoTableColumnsV1(),
      patientData: [],
      switchLabel: this.$t("common.photoLabel"),
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
      reqData: modelService.reqDataModel(),
      helpMsg: this.$t("helpMsg.patient"),
      isDev: process.env.NODE_ENV === "development",
    };
  },
  watch: {
    isSurveySetOpen(val) {
      console.log("isSurveySetOpen", val);
      // patientDraw 창 사이즈변경
      this.patientDrawerWidth = val ? "100%" : "90%";
    },
  },
  mounted() {
    if (this.meInfo !== null && this.meInfo.hospitalNum === 0) {
      alert("Please register your clinic first");
      this.$router.push("/clinic");
      return;
    }
    if (this.meInfo !== null && this.meInfo.userType !== "super")
      this.reqData.hospital_id = this.meInfo.hospital_id;
    this.getData();
  },
  methods: {
    /**** 클리닉 선택 ***/
    updateSelectClinic(e) {
      this.reqData.hospital_id = e === "" ? 0 : e;
      this.getData();
    },
    updateSelectService(e) {
      this.reqData.serviceType = e;
      this.getData();
    },
    updateSelectDiagnosis(e) {
      this.reqData.diagnosisType = e;
      this.getData();
    },
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 중간 ***/
    changeSwitch(e) {
      console.log("e", e);
      this.viewPhotoMode = e.length === 0 ? "N" : "Y";
    },
    xlsxSave() {
      this.$helper.xlsxDownload(this.xlsxData, "sheet1", "PatientList");
    },
    clickNewBtn() {
      console.log("", this.reqData.hospital_id);
      this.patientData.hospital_id = this.reqData.hospital_id;
      this.$refs.refPatientAdd.Open(
        this.patientData,
        this.patientData.hospital_id
      );
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    clickRow(e) {
      console.log("clickRow", e);
      this.$refs.refPatientAdd.Open(e, e.hospital_id);
    },
    memoClick(e) {
      console.log("memoClick", e);
      this.$refs.refMemoInput.Open(e);
    },
    bookingClick(e) {
      console.log("bookingClick", e);
      this.$refs.refDialogPatientBookingInfo.Open(e);
    },
    payClick(e) {
      console.log("payClick", e);
      this.$refs.refDialogPatientPayment.Open(e);
    },
    nameClick(e) {
      console.log("patient nameClick", e);
      this.patientDrawer = !this.patientDrawer;
      setTimeout(() => {
        this.$refs.refPatientDrawer._updateData(e);
      }, 300);
    },
    /**** 저장/수정/삭제 후 처리 ****/
    userEditorCompleteSaveData() {
      console.log("complete");
      this.getData();
    },
    /**** 데이터읽기 ****/
    async getData() {
      this.isLoading = true;
      this.patientData = [];
      const $h = this.$helper;
      // this.reqData.endpoint = "patient";
      this.reqData.endpoint = "patientList";
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            isUserTable: true,
            name: `${item.name}(${item.patient_id})`,
            nameFull: `${$h.changeNameFullDp(
              item.fname,
              item.mname,
              item.lname
            )}(${item.user_id})`,
            genderShort: $h.changeGenderShortForDp(item.gender),
            age: $h.changeDOBToAgeForDp(item.dob),
            // ccmCareTimeLabel: $h.secondsToTime(item.ccmcareTime, true),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            useYNLabel: $h.memberTypeLbl(item.activated),
            signupPathLabel: $h.signupPathLabel(item.signupPath),
            isPhoto: $h.isPhotoChecker(item.userPhotoImg),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            // patientMemo: $h.patientMemo(item.memo),
            hospitalName: `${$h.definedStringI18($h.makeStringToArray(item.hospitalName, '@@'), 'kr')}`,
            cardTitle: `${item.name}`,
            cardSubTitle: `${$h.changeDOBToAgeForDp(item.dob)}세/${$h.changeGenderShortForDp(item.gender)}/${item.patientCode}`,
            cardImg: $h.makeUserAvatarImg(
              item.userPhotoImg,
              $h.isPhotoChecker(item.userPhotoImg),
              item.gender
            ),
            cardRegistrationDate: $h.changeDateToStringForDp(
              item.registrationDate,
              true
            ),
            bookingBtnColor: this.makeBookingBtnColor(item.bookings, item.status),
          }));
        }
        console.log("this.patientData", res);
        this.patientData = res;
        this.isLoading = false;
      });
    },
    makeBookingBtnColor(bookings, bookingStatus) {
      const itemIdx = bookings.map((x) => x.confirm).indexOf("Y");
      // console.log('makeBookingBtnColor bookings', bookings, itemIdx);
      let retVal = "";
      if(bookingStatus === '병원확인' && itemIdx > -1) {
        retVal = "blue";
      }else if(bookingStatus === '예약' || bookingStatus === '환자예약') {
        retVal = "red";
      }
      return retVal;
    },
    memoSaveComplete() {
      this.getData();
    },
    onClose() {
      console.log("onClose");
      // this.$refs.refPatientDrawer.onClose();
      // setTimeout(() => this.patientDrawer = false, 3000);
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo", "isSurveySetOpen"]),
  },
};
</script>
<style lang="scss">
.uiSelectBox {
  display: flex;
  width: 200px;
}
</style>