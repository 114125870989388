<template>
  <v-dialog v-model="visible" max-width="1100px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        <img v-if="bookingData"
          class="mr-md rounded-circle mr-2"
          height="44"
          width="44"
          :src="bookingData.cardImg"
        />
        [{{ bookingData && bookingData.cardTitle }}]환자 수납
      </v-card-title>

      <v-card-text id="paymentForm" >
        <v-row>
          <v-col cols="12"><h5>선택된 진료 내용</h5></v-col>
        </v-row>
        <v-data-table
            v-model="selected"
            :items-per-page="10000"
            :hide-default-footer="true"
            :headers="paymentColumns"
            :items="paymentData"
            :single-select="false"
            item-key="product_id"
            show-select
            class="elevation-1"
          />

          <v-row class="mb-10" />

          <!-- 지불할 내용 추가폼 -->
          <div>
            <v-row class="pb-10">
              <v-col cols="2"><h5>지불할 내용</h5></v-col>
              <v-col cols="10"><v-btn color="primary" depressed small @click="addPayment">지불내용 추가</v-btn></v-col>
            </v-row>

            <!-- 내용 -->
            <paymentComp 
              v-for="(item, index) in selected" :key="index" 
              :item="item" 
              :index="index"
              :bookingData="bookingData"
              @changeInput="changeInput"
              @clickDeletePay="clickDeletePay"/>

            <!-- 합계 -->
            <v-row v-if="selected.length > 0">
              <v-col cols="4">
                <h3 style="text-align: right;">합계</h3>
              </v-col>
              <v-col cols="4">
                <h3>{{ $helper.commaNum(totalPayNum) }} 원</h3>
              </v-col>
            </v-row>
          </div>

          <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
            <v-btn color="primary" depressed large :disabled="selected.length > 0? false:true" @click="saveData">수납처리</v-btn>
          </v-card-actions>

          <v-divider></v-divider>
          <v-row class="mb-10" />

          <!-- 지불한내용 -->
          <PatientPaymentList 
            :bookingData="bookingData"
            :data="paidData" 
            :totalPaidNum="totalPaidNum"
            @updateDeleteComplete="getData"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import PatientPaymentList from "./payment/PatientPaymentList.vue";
import paymentComp from "./payment/paymentComp.vue";
// import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");
// const rulesService = require("@/utils/rules.js");
// const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    PatientPaymentList,
    paymentComp,
    // RadioComps
  },
  data() {
    return {
      isAddForm: false,
      selected: [],
      visible: false,
      bookingData: null,
      paymentData: [],
      paidData: [],
      reqData: modelService.reqDataModel(),
      paymentColumns: formatService.paymentTableColumnsV1(),
      totalPayNum: 0,
      totalPaidNum: 0,
      // itemChargeType: formatService.itemChargeType(),
    };
  },
  methods: {
    Open(bookingData) {
      console.log("bookingData", bookingData);
      this.bookingData = bookingData;
      this.visible = true;
      this.selected = [];
      this.totalPayNum = 0;
      this.totalPaidNum = 0;
      this.getData();

      setTimeout(() => {
        this.ScrollTop();
      }, 300);
    },
    close() {
      this.visible = false;
    },
    addPayment() {
      const tObj = {
        productNameKr: "지불할내용",
        paidValue: "",
      }
      this.selected.push(tObj)
    },
    clickDeletePay(i) {
      this.selected[i].paidValue = 0;
      // console.log('this.selected', this.selected[i]);
      // return;
      this.selected.splice(i, 1);
      this.calPaymentValue();
    },
    changeInput(item) {
      // console.log('item', item, Number(item.paidValue));
      // if(Number(item.paidValue) < 0) {
      //   this.$helper.showTostMessage("", "지불할 금액이 -일수는 없습니다.", "error");
      //   return;
      // }
      // this.calChargeValue(item);
      this.calPaymentValue();
    },
    // calChargeValue(item) {      
    //   // 수수료 계산
    //   let targetCharge = 0;
    //   let defaultSurgeryCharge = this.$helper.defineChargeWidthNation(this.bookingData).surgery;
    //   let defaultProcedureCharge = this.$helper.defineChargeWidthNation(this.bookingData).procedure;
    //   if(item.chargeType === '수술') {
    //     targetCharge = defaultSurgeryCharge / 100;
    //   }else if(item.chargeType === '시술') {
    //     targetCharge = defaultProcedureCharge / 100;
    //   }
    //   item.charge = (Number(item.paidValue) * targetCharge).toFixed(1);
    //   this.$forceUpdate();
    //   // console.log('calChargeValue', item.charge);
    // },
    calPaymentValue() {
      this.totalPayNum = 0;
      this.selected.forEach(element => {
        this.totalPayNum = this.totalPayNum + Number(element.paidValue);
      });
    },
    // paidClickUpdate(item) {
    //   // console.log('paidClickUpdate', item);
    //   const $h = this.$helper;
    //   if(item.productNameKr === '') {
    //     $h.showTostMessage("", "지불할 내용이 입력되지 않았습니다.", "error");
    //     return;
    //   }

    //   if(item.paidValue === '' || item.paidValue === 0) {
    //     $h.showTostMessage("", "지불할 금액이 입력되지 않았습니다.", "error");
    //     return;
    //   }

    //   if(Number(item.paidValue) > 9999999999) {
    //     $h.showTostMessage("", "지불할 금액은 1,000,000,000을 넘을 수 없습니다.", "error");
    //     return;
    //   }

    //   if(Number(item.paidValue) < 0) {
    //     this.$helper.showTostMessage("", "지불할 금액이 -일수는 없습니다.", "error");
    //     return;
    //   }

    //   console.log('paidClickUpdate', item);
    //   this.paidHistorySave(item);
    // },
    // paidClickDelete(item) {
    //   console.log('paidClickDelete', item);
    //   if (!confirm(`지불내용을 삭제하시겠습니까?`)) return;
    //   item.useYN = "N";
    //   this.paidHistorySave(item);
    // },
    // paidHistorySave(item) {
    //   const $h = this.$helper;
    //   item.paidValue = Number(item.paidValue)
    //   item.endpoint = "patientPaymentUpdate";
    //   // item.charge = item.chargeType === '이벤트'? 0:item.charge;
    //   this.saveDataQuery(item).then((resP) => {
    //     if(resP === undefined) return;
    //     let msg = "S"
    //     if(item.useYN === 'N') {
    //       msg = "D"
    //     }
    //     $h.showTostMessage("", $h.commonMessage(msg), "success");
    //     this.getData();
    //   })
    // },
    // 수납 할 내용 / 수납내용
    getData() {
      const $h = this.$helper;
      this.paidData = [];
      this.paymentData = [];
      this.bookingData.endpoint = 'patientPaymentInfo'
      this.getDataQuery(this.bookingData).then((resP) => {
        if(resP === undefined) return;
        let res = resP.paymentData.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            productNameKr: $h.definedStringI18($h.makeStringToArray(item.productName, "@@"), 'kr'),
            productPriceLbl: $h.commaNum(item.productPrice),
            productSalePriceLbl: $h.commaNum(item.productSalePrice),
          }));
        }
        console.log("paymentData", res);
        this.paymentData = res;

        let res2 = resP.paidData.data;
        if (0 < res2.length) {
          res2 = res2.map((item, index) => ({
            ...item,
            no: index + 1,
            // productNameKr: $h.definedStringI18($h.makeStringToArray(item.productNameKr, "@@"), 'kr'),
            paidValueLbl: $h.commaNum(item.paidValue),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
          }));
        }
        console.log("paidData", res2);
        this.paidData = res2;
        this.checkTotalPaidNum(res2)
      })
    },
    checkTotalPaidNum(paidData) {
      this.totalPaidNum = 0;
      paidData.forEach(element => {
        this.totalPaidNum = this.totalPaidNum + Number(element.paidValue);
      });
    },
    // 수납처리
    saveData() {
      // console.log('selected', this.selected);
      // return;
      const $h = this.$helper;
      const D = this.selected;
      let errorNum = 0;
      D.forEach(element => {
        if(element.paidValue < 0 || element.paidValue === 0 || element.paidValue === '') {
          // console.log('element.paidValue', element);
          errorNum++;
        }
      });

      if(errorNum > 0) {
        $h.showTostMessage("", "지불할 금액이 입력되지 않았습니다.", "error");
        return;
      }

      // 최대금액확인
      let errorNum2 = 0;
      D.forEach(element => {
        if(Number(element.paidValue) > 9999999999) {
          errorNum2++;
        }
      });

      if(errorNum2 > 0) {
        $h.showTostMessage("", "지불할 금액은 1,000,000,000을 넘을 수 없습니다.", "error");
        return;
      }

      D.forEach(element => {
        element.charge = element.chargeType === '이벤트'? 0:element.charge;
      });

      // 최종저장
      const reqData = {
        hospital_id: this.bookingData.hospital_id,
        patient_id: this.bookingData.patient_id,
        paymentData: D
      }
      // console.log("reqData", reqData);
      // return;

      if (!confirm(`수납처리 하시겠습니까?`)) return;
      this.saveAction(reqData);
    },
    saveAction(reqData) {
      const $h = this.$helper;
      reqData.endpoint = "patientPayment";
      this.saveDataQuery(reqData).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        // this.visible = false;
        // this.$emit("complete", res);
        this.selected = [];
        this.calPaymentValue();
        this.getData();
      });
    },
    ScrollTop() {
      console.log("Scroll");
      const scrollTarget = document.getElementById("paymentForm");
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.editorPadding {
  padding: 40px;
}

.v-input {
  padding-top: 0 !important;
}
</style>