<template>
  <div>
    <v-row>
      <v-col class="mt-1 mb-2">
        <v-text-field 
          label="여권번호" width="100%" placeholder="여권번호" ref="refPassport" 
          v-model="bookingData.passport" :rules="[filedRules.required]" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MessengerSelectTypeAndId 
          :messengerType="bookingData.messengerType" 
          :propsMessengerId="bookingData.messengerId"
          @changeSelect="bookingData.messengerType = $event"
          @changeInput="bookingData.messengerId = $event"
          :rules="[filedRules.required]"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MessengerSelectTypeAndId from "@/components/commonV2/ui/MessengerSelectTypeAndId.vue";
const rulesService = require("@/utils/rules.js");

export default {
  components: { MessengerSelectTypeAndId },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    }
  },
}
</script>
<style lang="">

</style>