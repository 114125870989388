<template>
  <base-card class="localCard">
    <v-card-text>
      <div>
        <v-row>
          <v-text-field
            width="width: 100%"
            ref="refTitle"
            label="지불한내용"
            placeholder="지불한내용"
            v-model="item.productNameKr"
          />
        </v-row>
        <v-row>
          <v-text-field
            ref="refPaidValue"
            label="지불금액"
            type="Number"
            prefix="￦"
            v-model="item.paidValue"
            placeholder="지불금액"
            @input="changeInput(item)"
          />
        </v-row>
        <v-row v-if="item.chargeType === '이벤트'">
          <v-text-field
            ref="refChargeProg"
            type="number"
            label="수수료율"
            placeholder=""
            prefix="%"
            @input="changeProgInput(item, $event)"
          />
        </v-row>
        <v-row>
          <v-text-field
            ref="refCharge"
            type="number"
            label="수수료"
            placeholder=""
            prefix="￦"
            v-model="item.charge"
            disabled
          />
        </v-row>
        <v-row>
          <RadioComps
            :prosIsRow="true"
            :items="itemChargeType"
            :prosSelected="item.chargeType"
            @changeType="
              item.chargeType = $event;
              calChargeValue(item);
              changeType($event)"
          />
        </v-row>
        <v-row>
          <p class="text--disabled caption ma-0">
            처리일자 : {{ regDateLabel }}
          </p>
        </v-row>
      </div>
      <!-- <v-divider /> -->
      <div class="d-flex">
        <v-btn class="ma-2" color="" small depressed @click="paidClickUpdate(item)">
          수정
        </v-btn>
        <v-btn class="ma-2" color="danger" small depressed @click="paidClickDelete(item)">
          삭제
        </v-btn>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
import { mapActions } from "vuex";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { RadioComps },
  props: {
    bookingData: {
      type: [Object, Array]
    },
    // Floating Button icon Color
    avatar: {
      type: String,
      default: require("@/assets/images/avatars/004-bald.svg"),
    },
    item: {
      type: [Object, Array]
    },
    title: {
      type: String,
      default: "Timothy Clarkson",
    },
    subTitle: {
      type: String,
      default: "Vue Developer",
    },
    regDateLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // chargeDisabled: false,
      itemChargeType: formatService.itemChargeType(),
    }
  },
  watch: {
    item: {
      handler(val, oldVal) {
        // this.chargeDisabled = false;
        // this.chargeDisabled = val.chargeType === '이벤트'? false:true;

      },
      immediate: true // 컴포넌트가 생성되자마자 즉시 실행
    }
  },
  methods: {
    changeType(e) {
      console.log('changeType', e);
      // this.chargeDisabled = false;
      // this.chargeDisabled = true;
      if(e === '이벤트') {
        // 수수료 disable해제
        // this.chargeDisabled = false;
        setTimeout(() => {
          this.$refs.refChargeProg.focus();
        }, 300);
      }
    },
    // 수수료율 변경
    changeProgInput(item, e) {
      // console.log('changeProgInput');
      console.log('item', e, Number(item.paidValue));
      if(Number(e) < 0) {
        this.$helper.showTostMessage("", "수수료는 -일수는 없습니다.", "error");
        return;
      }
      let targetCharge = Number(e) / 100;
      item.charge = (Number(item.paidValue) * targetCharge).toFixed(1);
      this.$forceUpdate();
    },
    changeInput(item) {
      console.log('item', item, Number(item.paidValue));
      if(Number(item.paidValue) < 0) {
        this.$helper.showTostMessage("", "지불할 금액이 -일수는 없습니다.", "error");
        return;
      }
      this.calChargeValue(item);
    },
    // 수수료 계산
    calChargeValue(item) {    
      if(item.chargeType === '이벤트') {
        // originCharge값
        item.charge = item.originCharge;
        return;  
      }
      let targetCharge;
      let defaultSurgeryCharge = this.$helper.defineChargeWidthNation(this.bookingData).surgery;
      let defaultProcedureCharge = this.$helper.defineChargeWidthNation(this.bookingData).procedure;
      if(item.chargeType === '수술') {
        targetCharge = defaultSurgeryCharge / 100;
      } else if(item.chargeType === '시술') {
        targetCharge = defaultProcedureCharge / 100;
      }
      item.charge = (Number(item.paidValue) * targetCharge).toFixed(1);
      this.$forceUpdate();
      // console.log('calChargeValue', this.bookingData, this.$helper.defineChargeWidthNation(this.bookingData), defaultSurgeryCharge, targetCharge, item.charge, item.chargeType);
      console.log('calChargeValue',item.charge); 
    },
    paidClickUpdate(item) {
      // console.log('paidClickUpdate', item);
      const $h = this.$helper;
      if(item.productNameKr === '') {
        $h.showTostMessage("", "지불할 내용이 입력되지 않았습니다.", "error");
        return;
      }

      if(item.paidValue === '' || item.paidValue === 0) {
        $h.showTostMessage("", "지불할 금액이 입력되지 않았습니다.", "error");
        return;
      }

      if(Number(item.paidValue) > 9999999999) {
        $h.showTostMessage("", "지불할 금액은 1,000,000,000을 넘을 수 없습니다.", "error");
        return;
      }

      if(Number(item.paidValue) < 0) {
        this.$helper.showTostMessage("", "지불할 금액이 -일수는 없습니다.", "error");
        return;
      }

      console.log('paidClickUpdate', item);
      this.paidHistorySave(item);
    },
    paidClickDelete(item) {
      console.log('paidClickDelete', item);
      if (!confirm(`지불내용을 삭제하시겠습니까?`)) return;
      item.useYN = "N";
      this.paidHistorySave(item);
    },
    paidHistorySave(item) {
      const $h = this.$helper;
      item.paidValue = Number(item.paidValue)
      item.endpoint = "patientPaymentUpdate";
      // item.charge = item.chargeType === '이벤트'? 0:item.charge;
      this.saveDataQuery(item).then((resP) => {
        if(resP === undefined) return;
        let msg = "S"
        if(item.useYN === 'N') {
          msg = "D"
        }
        $h.showTostMessage("", $h.commonMessage(msg), "success");
        this.$emit('updateDeleteComplete')
      })
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.localCard {
  box-shadow: rgba(47, 60, 74, 0.1) 0px 8px 32px, rgba(47, 60, 74, 0.02) 0px 8px 16px !important;
  .title {
    font-size: 18px;
  }
  .paid {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>