<template>
  <v-card-text id="bookingForm" >
    <v-form ref="form" class="d-flex">

      <BookingInfo :bookingData="bookingData" /> 

      <div class="hGap"></div>

      <BookingAddInfo :bookingData="bookingData" />

    </v-form>
  </v-card-text>
</template>
<script>
import BookingInfo from "./patientBooking/BookingInfo.vue";
import BookingAddInfo from "./patientBooking/BookingAddInfo.vue";

export default {
  components: {
    BookingInfo,
    BookingAddInfo
  },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}

.hGap {
  width: 200px !important;
}
</style>