<template>
  <div class="d-flex">
    <div class="dateTimeBox">
      <DatePickerWithInputText 
        selectStyleWidth="width: 150px"
        :label="propsDateLabel"
        :value="date"
        @onChangeDate="onChangeDateDatePicker" />
    </div>
    <SelectBox 
      selectStyleWidth="width: 140px"
      :label="propsTimeLabel"
      :value="time" 
      :items="timeItems" 
      @changeSelect="timeUpdateSelect" />
  </div>
</template>

<script>
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { DatePickerWithInputText, SelectBox },
  props: {
    propsData: {
      type: [Object, Array],
    },
    propsDateLabel: {
      type: [String],
      default: "Select Date",
    },
    propsTimeLabel: {
      type: [String],
      default: "",
    },
  },
  watch: {
    propsData(val) {
      this.date = val === undefined? "":val.date;
      this.time = val === undefined? "":val.time;
    },
  },
  data() {
    return {
      timeItems: formatService.timeList(),
      date:
        this.propsData === undefined
          ? "" //this.$helper.getToDateStringType()
          : this.propsData.date,
      time: this.propsData === undefined ? "" : this.propsData.time,
    };
  },
  methods: {
    onChangeDateDatePicker(e) {
      if (e === "" || e === null) return;
      this.date = e;
      this.finallyEmit();
    },
    timeUpdateSelect(e) {
      this.time = e;
      this.finallyEmit();
    },
    finallyEmit() {
      const DateTime = {
        date: this.$helper.changeDateToString(this.date),
        time: this.time,
      };
      this.$emit("complete", DateTime);
    },
  },
};
</script>

<style lang="scss">
.dateTimeBox {
  padding-top: 6px;
}
</style>