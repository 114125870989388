<template>
  <v-dialog v-model="visible" max-width="1000px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> [{{ userData && userData.nameFull }}] Memo </v-card-title>
      <v-card-text>
        <v-row class="mb-10">
          <v-col>
            <VueEditor v-model="editorData" :editorToolbar="customToolbar" />

            <div v-if="reqData.m_id > 0">
              Update Date : {{ this.dpDateLbl }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" depressed large @click="clickSave"> SAVE</v-btn>
        <v-btn color="primary" outlined large @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- <a-modal v-model="visible" width="1100px" centered :afterClose="afterClose">
    <template slot="footer">
      <a-button key="submit" type="primary" @click="clickSave">SAVE</a-button>
    </template>

    <div class="modal-content">
      <h2 class="modal-title">[{{ userData && userData.nameFull }}] Memo</h2>
      <VueEditor v-model="editorData" :editorToolbar="customToolbar" />
    </div>

    <div v-if="reqData.m_id > 0">Update Date : {{ this.dpDateLbl }}</div>
  </a-modal> -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
export default {
  components: { Header, VueEditor },
  data() {
    return {
      visible: false,
      userData: null,
      requiredMsg: requireService.requiredFieldAlertMessage(),
      reqData: modelService.patientMemoModel(),
      dpDateLbl: "",
      editorData: "",
      customToolbar: [
        // ['bold', 'italic', 'underline'],
        // [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        // [{ color: [], background: [] }],
        // ['link'],
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ header: 1 }, { header: 2 }],
        // ['blockquote', 'code-block'],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        // [{ script: 'sub' }, { script: 'super' }],
        // [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
        ["link"],
        // [{ direction: 'rtl' }],
        ["clean"],
      ],
    };
  },
  methods: {
    Open(userData) {
      this.visible = true;
      this.userData = userData;
      // 초기화
      this.dpDateLbl = "";
      this.editorData = "";
      this.reqData = modelService.patientMemoModel(); // 초기화
      console.log("dpDateLbl", this.dpDateLbl);
      this.getData();
    },
    async getData() {
      const D = this.reqData;

      D.user_id = this.userData.user_id;
      D.hospital_id = this.userData.hospital_id;
      await this.getDataQuery(D).then((res) => {
        if (res === undefined) return;
        console.log("res", res);

        this.dpDateLbl = this.$helper.changeDateToForUpdateDp(
          res.regDate,
          res.updDate
        );
        this.editorData = res.memo;
        this.reqData = res;
        if (res === "") {
          this.reqData = modelService.patientMemoModel(); // 초기화
        }
        this.reqData.endpoint = "patient/memo";

        setTimeout(() => {
          // this.$refs.refMemo.focus();
        }, 500);
      });
    },
    async clickSave() {
      // 필수
      const D = this.reqData;
      let msg = `Memo ${this.requiredMsg}`;
      console.log("this.editorData", this.editorData);
      if (this.editorData === "" || this.editorData === undefined) {
        this.$helper.showTostMessage("", msg, "error");
        return;
      }

      D.memo = this.editorData;
      D.user_id = this.userData.user_id;
      D.hospital_id = this.userData.hospital_id;
      // console.log('', D);
      // return;
      await this.saveDataQuery(D).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage(
          "",
          this.$helper.commonMessage("S"),
          "success"
        );
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    close() {
      this.visible = false;
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
.height {
  min-height: 250px !important;
}
</style>