<template>
  <v-row>
    <v-col>
      <v-textarea
        ref="refDescription"
        v-model="data.description"
        label="기타 환자메모"
        placeholder="기타 환자메모"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
};
</script>
<style lang="scss">
</style>