<template>
  <div class="text-center">
    <v-overlay v-model="isLoading">
      <v-progress-circular
        indeterminate
        :size="circularSize"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    circularSize: {
      type: Number,
      default: 98,
    },
  },
};
</script>
<style lang="">
</style>