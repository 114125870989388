<template>
  <!-- <v-col> -->
  <div class="subMain">
    <div class="headTitle">{{ $t('patient.purchaseInfoTitle') }}</div>
    <div class="patient_vGap"></div>
    <div class="patientEdit_vGap" v-if="data.user_id === 0"></div>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.purchaseInfo.surgeryClinic')"
            :placeholder="$t('patient.detail.purchaseInfo.surgeryClinic')"
            ref="refName"
            v-model="data.patientAddInfo2.surgeryClinic"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.purchaseInfo.surgeryProduct')"
            :placeholder="$t('patient.detail.purchaseInfo.surgeryProduct')"
            ref="refName"
            v-model="data.patientAddInfo2.surgeryProduct"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.purchaseInfo.prepaid')"
            :placeholder="$t('patient.detail.purchaseInfo.prepaid')"
            ref="refName"
            v-model="data.patientAddInfo2.prepaid"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.purchaseInfo.paidInKorea')"
            :placeholder="$t('patient.detail.purchaseInfo.paidInKorea')"
            ref="refName"
            v-model="data.patientAddInfo2.paidInKorea"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('patient.detail.purchaseInfo.paidUnit')"
            :placeholder="$t('patient.detail.purchaseInfo.paidUnit')"
            ref="refName"
            v-model="data.patientAddInfo2.paidUnit"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
  <!-- </v-col> -->
</template>
<script>
// import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";

const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    // DatePickerWithInputText,
  },
  props: {
    data: {
      type: [Object, Array],
    },
    staffTypeList: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    };
  },
  mounted() {
  },
  methods: {
    // onChangeHopeSurgeryDay1(e) {
    //   this.data.patientAddInfo1.hopeSurgeryDay1 = e;
    // },
    // onChangeHopeSurgeryDay2(e) {
    //   this.data.patientAddInfo1.hopeSurgeryDay2 = e;
    // },
  },
};
</script>
<style lang="scss">
.patient_vGap {
  height: 10px;
}
.patientEdit_vGap {
  height: 7px;
}
</style>