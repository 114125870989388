<template>
  <div>
    <v-row>
      <v-col cols="12"><h5>지불한 내용 : {{ $helper.commaNum(totalPaidNum) }}원</h5></v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <template>
          <v-row>
            <v-col
              v-for="(item, index) in data"
              :key="index"
              cols="12"
              sm="4"
              md="4"
              lg="4"
            >
              <!-- $emit('clickUpdate', item) -->
              <list-card
                :bookingData="bookingData"
                :item="item"
                :title="item.productNameKr"
                :sub-title="item.paidValueLbl"
                :regDateLabel="item.dpDateLbl"
                @updateDeleteComplete="$emit('updateDeleteComplete')"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ListCard from "./paymentCard.vue";
export default {
  components: { ListCard },
  props: {
    bookingData: {
      type: [Object, Array]
    },
    data: {
      default: () => [],
      type: Array,
    },
    totalPaidNum: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style lang="scss">
.photoBox {
  text-align: center;
}
.iconStyle {
  font-size: 60px !important;
  color: rgb(210, 210, 210) !important;
}
.regTag {
  color: darkgray;
}
</style>