<template>
  <div>
    <v-row>
      <v-col md="12">
        <template>
          <v-row>
            <v-col
              v-for="(item, index) in data"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <list-card
                :footerBtn="$t('patient.detail.photoCared.button.medicalInfo')"
                :footerBtnTwo="$t('patient.detail.photoCared.button.profile')"
                :footerBtnThree="$t('patient.detail.photoCared.button.memo')"
                :footerBtnFour="$t('patient.detail.photoCared.button.booking')"
                :footerBtnFourColor="item.bookingBtnColor"
                :isCanPay="item.status==='' || item.status==='예약' || item.status==='환자취소' || item.status==='병원예약취소'? false:true"
                :avatar="item.cardImg"
                :title="item.cardTitle"
                :sub-title="item.cardSubTitle"
                :hospitalName="$helper.definedStringI18($helper.makeStringToArray(item.hospitalName, '@@'), 'kr')"
                :registrationDate="item.cardRegistrationDate"
                :progress-linear-color="item.progressLinearColor"
                @medicalInfo="clickRow(item)"
                @profile="profile(item)"
                @memo="memo(item)"
                @booking="booking(item)"
                @pay="$emit('payClick', item)"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ListCard from "./comp/ListCard.vue";
import PatientAvatar from "./comp/PatientAvatar.vue";
export default {
  components: { PatientAvatar, ListCard },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    columns: {
      default: () => [],
      type: Array,
    },
    customColumn: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      items: [
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          title: "Timothy clarkson",
          subTitle: "React Developer",
          progressLinearColor: "primary",
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    clickRow(item) {
      console.log("", item);
      this.$emit("clickRow", item);
    },
    profile(item) {
      console.log("", item);
      this.$emit("nameClick", item);
    },
    memo(item) {
      console.log("", item);
      this.$emit("memoClick", item);
    },
    booking(item) {
      this.$emit("bookingClick", item);
    },
    // booking(item) {
    //   this.$emit("bookingClick", item);
    // }
  },
};
</script>

<style lang="scss">
.photoBox {
  text-align: center;
}
.iconStyle {
  font-size: 60px !important;
  color: rgb(210, 210, 210) !important;
}
.regTag {
  color: darkgray;
}
</style>