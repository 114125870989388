<template>
  <v-combobox
    item-text="tag"
    item-value="code"
    placeholder="Enter at least 3 digits."
    small-chips
    deletable-chips
    :label="$t('patient.detail.panel.gen.icdCode')"
    :value="value && value.icdCode ? value.icdCode.split(',') : []"
    :multiple="true"
    :items="itemsICD"
    @keyup="ICDCheck"
    @change="ICDUpdate"
    @blur="ICDUpdate"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";
// const formatService = require("@/utils/format.js");
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      itemsICD: [],
      itemsHistory: [],
      reqData: {
        endpoint: "icdCode",
        searchGbn: "",
        searchVal: "",
      },
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (val === null) return;
        if (val.icdCode) {
          val.icdCode = val.icdCode.replace(/\s/g, "");
          const temp = val.icdCode.replace(/\s/g, "").split(",");
          this.itemsHistory = new Set([...temp, ...this.itemsHistory]);
        }
      },
    },
  },
  mounted() {
    if (this.value === null) return;
    try {
      this.value.icdCode = this.value.icdCode.replace(/\s/g, "");
      const temp = this.value.icdCode.replace(/\s/g, "").split(",");
      this.itemsHistory = new Set([...temp]);
    } catch (e) {}
  },
  methods: {
    async ICDCheck(e) {
      const val = e.target.value;
      this.reqData.searchVal = val;
      if (val.length > 2) {
        this.getDataQuery(this.reqData).then((resP) => {
          if (resP === undefined) return;
          // console.log("", resP.data);
          let a = resP.data;
          this.itemsICD = [...a];
          this.itemsHistory = new Set([
            ...a.map((item) => item.code),
            ...this.itemsHistory,
          ]);
        });
      }
    },
    ICDUpdate(val) {
      console.log('check', val.target);
      console.log('ICDUpdate');
      
      if (val.target) {
      } else {
        // 엔터쳐서 입력하는 경우와 클릭으로 선택하는 케이스.
        const lastVal = val[val.length - 1]
          ? val[val.length - 1].code
          : undefined;
        // 선택하는 경우 (선택해제도 포함)
        if (lastVal) {
          const index = [...this.itemsHistory].findIndex((e) => e === lastVal);
          // itemsHistory : 지금까지 검색했거나 선택했던 모든 코드들 세트. 이중에서 없으면 무효한 값이라고 판단해서 val에서 제거
          if (index === -1) {
            val = val.slice(0, -1);
          }
        }
        //엔터치는 경우
        else {
          const index = [...this.itemsHistory].findIndex(
            (e) => e === val[val.length - 1]
          );

          if (index === -1) {
            val = val.slice(0, -1);
          }
        }
        let data = val.map((item) => {
          //기존 값들 + 마지막에는 선택한 객체
          const x = item.code ? item.code : item;
          return x;
        });
        data = data.join(",");
        this.value.icdCode = data;
        this.$emit("updateValue", { type: "icdCode", data: data });
        this.$forceUpdate();
      }
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.icdCodeBox {
  display: flex;
  width: 100%;
}
</style>