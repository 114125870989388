<template>
  <v-col>
    <div class="subMain">
      <div class="headTitle">{{ $t('patient.prescriptionTitle') }}</div>
      <v-card-text>
        <v-row>
          <v-col>
            <SelectBoxPrimaryPhysician
              ref="refPrimaryPhysician"
              :userData="data"
              :hospital_id="data.hospital_id"
              :selectedValue="data.primaryPhysician"
              @updateSelect="updateSelectPrimaryPhysician"
            />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <DateComp :calendar="propsCalendar" @dateChange="onChangeVisitDate" />
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col>
            <DateComp :calendar="propsCalendar" @dateChange="onChangeUpComingVisitDate" />
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col>
          </v-col>
        </v-row> -->
      </v-card-text>
    </div>
  </v-col>
</template>
<script>
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
export default {
  components: {
    SelectBoxPrimaryPhysician,
    // DateComp,
  },
  props: {
    data: {
      type: [Object, Array],
    },
    staffTypeList: {
      type: [Object, Array],
    },
  },
  watch: {
    data() {},
  },
  data() {
    return {
      propsCalendar: this.$moment(),
    };
  },
  methods: {
    updateSelectPrimaryPhysician(e) {
      this.data.primaryPhysician = e;
    },
    onChangeVisitDate() {},
    onChangeUpComingVisitDate() {},
  },
};
</script>
<style lang="">
</style>