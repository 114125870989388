<template>
  <v-dialog v-model="visible" max-width="1000px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        <img v-if="bookingData"
          class="mr-md rounded-circle mr-2"
          height="44"
          width="44"
          :src="bookingData.cardImg"
        />
        [{{ bookingData && bookingData.cardTitle }}]환자 예약정보
      </v-card-title>

      <PatientBookingForm ref="refPatientBookingForm" :bookingData="bookingData" />

      <BtnBox @save="saveData" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import PatientBookingForm from "./form/PatientBookingForm.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");
const rulesService = require("@/utils/rules.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    BtnBox,
    PatientBookingForm,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      birthdayRules: rulesService.birthdayRules(),
      SSNRules: rulesService.SSNRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      bookingData: null,
      reqData: modelService.reqDataModel(),
    };
  },
  methods: {
    Open(bookingData) {
      console.log("bookingData", bookingData);
      this.bookingData = bookingData;
      this.visible = true;

      setTimeout(() => {
        this.ScrollTop();
      }, 300);
    },
    close() {
      this.visible = false;
    },
    saveData() {
      const $h = this.$helper;
      const D = this.bookingData;
      let RF = this.$refs.refPatientBookingForm.$refs;

      // 필수 체크
      if (!RF.form.validate()) return;

      if (D.status === '병원확인') {
        if ((D.bookings[0].confirm === null || D.bookings[0].confirm === "") && 
            (D.bookings[1].confirm === null || D.bookings[1].confirm === "")) {
            let msg = "병원확인은 환자의 희망선택 중 하나를 선택해야 합니다.";
            $h.showTostMessage("", msg, "info");
            return;
        }
      }

      // 최종저장
      // console.log("", D);
      // return;
      const saveOrUpdate = D.patient_id > 0 ? "update" : "save";
      if (!confirm(`${saveOrUpdate} Patient information?`)) return;
      this.saveAction(D);
    },
    saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "booking";
      this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ScrollTop() {
      console.log("Scroll");
      const scrollTarget = document.getElementById("bookingForm");
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.editorPadding {
  padding: 40px;
}

.v-input {
  padding-top: 0 !important;
}
</style>