<template>
    <div class="info-text-icon">
      <div class="info-text">
        <img
          class="small-triangle"
          src="@/assets/images/common/triangle.png"
        />
        {{ msgType }}
      </div>
    </div>
</template>
<script>
export default {
  props: {
    msgType: {
      type: String,
      default: 'clinic'
    }
  },
  // data() {
  //   return {
  //     msg: '환자정보 처리에 대한 설명 ....',
  //     patient: '환자정보 처리에 대한 설명 ....',
  //     clinic: '병원정보 처리에 대한 설명 ....'
  //   }
  // },
  // watch: {
  //   msgType(val) {
  //     this.msg = this.clinic;
  //   }
  // }
}
</script>
<style lang="scss">
.info-text-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: top;
  position: relative;
  background: url(~@/assets/images/common/info_icon.png);
  background-size: cover;
  &:hover,
  &:active,
  &:focus {
    background: url(~@/assets/images/common/info_icon_hover.png);
    background-size: cover;
    .info-text {
      display: block;
    }
  }
  .info-text {
    display: none;
    position: absolute;
    width: 400px;
    background-color: white;
    color: #503291;
    height: auto;
    font-size: 13px;
    white-space: normal;
    padding: 23px;
    border: 1px solid #503291;
    border-radius: 4px;
    box-shadow: 0 0 1em #989b9b;
    line-height: 150%;
    bottom: 140%;
    left: -545%;
    cursor: default;
    z-index: 99;
    .small-triangle {
      width: 15px;
      height: 15px;
      position: absolute;
      background-size: 100%;
      bottom: -13px;
      background-repeat: no-repeat;
      left: 28%;
    }
  }
}
</style>