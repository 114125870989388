<template>
  <div class="bookingBoxRight">
    <div class="headTitle">예약 입력정보</div>
    <div class="patient_vGap"></div>
    <v-card-text>
      <ForCommon :bookingData="bookingData" />

      <ForSer :bookingData="bookingData" v-if="bookingData.hospitalType === 'severe'" />

      <ForSerOption :patientOptionInfo="bookingData.patientOptionInfo" v-if="bookingData.hospitalType === 'severe'" />

      <ForCommonOption :patientOptionInfo="bookingData.patientOptionInfo" />

    </v-card-text>
  </div>
</template>
<script>
import ForCommon from "./bookingAddInfo/ForCommon.vue";
import ForSer from "./bookingAddInfo/ForSer.vue";
import ForCommonOption from "./bookingAddInfo/ForCommonOption.vue";
import ForSerOption from "./bookingAddInfo/ForSerOption.vue";

export default {
  components: { ForCommon, ForSer, ForCommonOption, ForSerOption },
  props: {
    bookingData: {
      type: [Object, Array],
    },
  },
}
</script>
<style lang="scss">
.bookingBoxRight {
  width: 70%;
}
</style>